<template>
    <div class="projectList flex">
      <div :style="{width:itemWidth}"
      :class="['projectBox', column === 4 ? 'even' : 'odd', marginRight === 16 ? 'marginRight16' :  marginRight === 20 ? 'marginRight20' : 'marginRight22']"
      v-for="(item,index) in list" :key="index"
      @click="detailsProjesct(item.id, item.level)">
      <div class="hongdian" v-if="item.is_remind === 1 && redDot"></div>
        <el-image
          style="width: 100%;object-fit: cover;"
          :class="marginRight === 16 ? 'widthImg22' :  marginRight === 20 ? 'widthImg20' : 'widthImg22'"
          :src="item.pic"
          fit="cover" />
        <div class="padding">
          <div class="title">{{item.name || ''}}</div>
          <div class="classification" v-if="item.cateOne">{{item.cateOne.name}}-{{item.cateTwo.name}}</div>
          <div class="company flex_ac"><span :class="item.user.certification_type === 1 ? 'active' : 'activeTion'">{{item.user.certification_type === 1 ? '个' : '企'}}</span>{{item.company_name}}</div>
          <div class="flex_jcsb_ac participateIn">
            <div class="eye">
              <i class="el-icon-view"></i>
              <span>{{item.view}}</span>
            </div>
            <el-button class="participateInBtn widthBtn" v-if="marginRight !== 20 && item.button === 1">我要参与</el-button>
          </div>
        </div>
      </div>
      <el-empty v-if="!list.length"></el-empty>
    </div>
</template>

<script>
import {myInfo} from '@/api/user/user'
export default {
  name: 'ProjectList',
  props: {
    list: {
      type: Array,
      default: () => []
    },
    column: {
      type: Number,
      default: 4
    },
    marginRight: {
      type: Number,
      default: 16
    },
    windowWidth: {
      type: Number,
      default: 1200
    },
    paddingAuto: {
      type: Number,
      default: 0
    },
    redDot: { // 红点显示
      type: String,
      default: ''
    }
  },
  methods: {
    detailsProjesct(id, level) {
      myInfo().then(res => {
        this.userInfo = res.data
        localStorage.setItem('userInfo', JSON.stringify(res.data))
      })
      if (level >= 4 && this.userInfo.is_info === 0) {
        this.$message.warning('请完善信息')
        this.$router.push('user/myTracks')
      } else if (level >= 4 && this.userInfo.is_certification === 0) {
        this.$message.warning('请进行认证')
        this.$router.push('user/myTracks')
      } else {
        if (level >= 4 && this.userInfo.is_vip === 0) {
          this.$message.warning('请联系客服成为会员')
          this.$router.push('user/myMember')
        } else {
          // this.$message.warning('提交成功,请您稍等审核信息')
          this.$router.push({name: 'detailsProjesct', query: {id}})
        }
      }
    }
  },
  data() {
    return {
      userInfo: '',
      itemWidth: `calc(${((this.windowWidth - (3 * this.marginRight) - (this.paddingAuto * 2)) / this.column)}px)`
    }
  },
  watch: {}
}
</script>

<style lang="scss" scoped>
  /deep/{
    .el-image{
      overflow: none;
    }
    .el-empty{
      margin: auto;
    }
  }
  .widthImg20{
    height: 138px;
  }
  .widthImg22{
    height: 188px;
  }
  .projectList {
    flex-wrap: wrap;
    .projectBox{
      background: #fff;
      margin-bottom: 16px;
      cursor: pointer;
      border:1px solid transparent;
      box-sizing: border-box;
      position: relative;
      .hongdian{
        width: 14px;
        height: 14px;
        background: #EE0E1E;
        border-radius: 50%;
        position: absolute;
        z-index: 99;
        top: 3px;
        left: 3px;
      }
      &.even:nth-child(4n+4){
        margin-right: 0;
      }
      &:hover{
        border: 1px solid $button-block;
        box-shadow: 0px 2px 16px 0px rgba(193, 204, 217, 0.5);
        .title{
          color: $button-block;
        }
      }
      &.marginRight16{
        margin-right: 16px;
      }
      &.marginRight20{
        margin-right: 20px;
        border:1px solid #E8E8E8;
      }
      &.marginRight22{
        margin-right: 22px;
        border:1px solid #E8E8E8;
      }
      .participateIn{
        margin-top: 16px;
        .widthBtn{
          height: 32px;
          width: 98px;
          line-height: 0;
        }
        .participateInBtn{
          border: none;
          color: #333;
          background: #F9D900;
          &:hover{
            color: #333;
            background: #F9BB00;
          }
        }
        .eye{
          .el-icon-view{
            color: #666;
            font-size: 16px;
          }
          span{
            margin-left: 5px;
            font-size: 16px;
          }
        }
      }
      .padding{
        padding: 15px;
        .title{
          font-size: 16px;
        }
        .classification{
          margin-top: 12px;
          font-size: 16px;
          color: #666;
        }
        .company{
          font-size: 14px;
          color: #666;
          margin-top: 12px;
          .active{
            width: 18px;
            height: 18px;
            background: #449CED;
            border-radius: 50%;
            line-height: 18px;
            color: #fff;
            text-align: center;
            margin-right: 7px;
          }
          .activeTion{
            width: 18px;
            height: 18px;
            background: #FFB400;
            border-radius: 50%;
            line-height: 18px;
            color: #fff;
            text-align: center;
            margin-right: 7px;
          }
        }
      }
    }
  }
</style>
