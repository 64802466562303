<template>
  <div class="container">
    <div class="searchBack flex_jc_ac">
      <Search :keyval="keyword" :searchType="'project'" @searchClick="searchClick" />
    </div>
    <Catalogue :firstId="cate_id_one" @searchProjectOne="searchProjectOne" @searchProjectTwo="searchProjectTwo" />
    <RegionalMembers :classifyList="cityMembersList" :cityId="city_id"
    @changeSecondClassify="changeSecondClassify" />
    <FilterList :filter="0" @changeFilter="changeFilter" />
    <div class="projectTop">
      <ProjectList :list="projectesList" />
      <div class="pagin">
        <Pagination :total="totalCount" :pageSize="16" @changeCurrentPage="changeCurrentPage" v-if="projectesList.length" />
      </div>
    </div>
  </div>
</template>

<script>
import FilterList from '@/components/sort/filterList'
import Catalogue from '@/components/sort/catalogue'
import RegionalMembers from '@/components/sort/regionalMembers'
import Search from '@/components/common/search'
import ProjectList from '@/components/common/projectList'
import { ProjectDataList, cityList } from '@/api/index/index'
import Pagination from '@/components/common/pagination'

export default {
  name: 'memberShow',
  components: {
    Search,
    RegionalMembers,
    ProjectList,
    Catalogue,
    Pagination,
    FilterList
  },
  data() {
    return {
      keyword: '',
      city_id: 0,
      totalCount: 0,
      page: 1,
      sort: 1, // 最新最热
      cate_id_one: '',
      cate_id_two: '',
      projectesList: [], // 项目数组
      cityMembersList: [] // 地区数组
    }
  },
  mounted() {
    // console.log('123', this.$route.query.id)
    this.keyword = this.$route.params.keyword
    this.city_id = this.$route.params.city_id
    if (this.$route.query.id) {
      this.cate_id_one = String(this.$route.query.id)
    }
    // console.log(this.city_id)
    this.project()
    this.rcityListegionalMmbers()
  },
  methods: {
    searchClick(key) {
      // console.log(key)
      this.keyword = key
      this.project()
    },
    changeFilter(sortId) {
      this.sort = sortId
      this.project()
    },
    searchProjectOne(id) {
      this.cate_id_two = id
      this.project()
    },
    searchProjectTwo(id) {
      this.cate_id_one = id
      this.project()
    },
    changeSecondClassify(id) { // 地区会员 返回id
      this.city_id = id
      this.project()
    },
    project() {
      const data = {
        keyword: this.keyword,
        psize: 16,
        cate_id_one: this.cate_id_one,
        cate_id_two: this.cate_id_two,
        page: this.page,
        sort: this.sort,
        city_id: this.city_id
      }
      ProjectDataList(data).then(res => {
        this.totalCount = res.data.count
        this.projectesList = res.data.list
        // console.log('打印', res.data)
      })
    },
    changeCurrentPage(value) {
      this.page = value
      this.project()
    },
    rcityListegionalMmbers() {
      const data = {
        pid: 0
      }
      cityList(data).then(res => {
        this.cityMembersList = res.data
      })
    }
  }
}
</script>

<style lang="scss" scoped>
  .container{
    width: 1200px;
    margin: 20px auto 0;
    .pagin{
      margin: 40px auto;
    }
    .projectTop{
      margin-top: 17px;
    }
    .searchBack{
      width: 868px;
      background: #fff;
      padding: 40px 166px;
    }
    .classifyList{
      background: #fff;
      padding: 0 40px;
    }
  }
</style>
